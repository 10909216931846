import React from 'react';
import './App.css';

function App() {
  return (
    <>
      <h1 className="title">Boost7</h1>
      <div className="container">
        <button onClick={() => window.location.href = 'https://discord.com/api/oauth2/authorize?client_id=1110635281532592158&redirect_uri=https%3A%2F%2Ffaas-lon1-917a94a7.doserverless.co%2Fapi%2Fv1%2Fweb%2Ffn-92ebb775-d5d1-4b93-9f08-615d315ae1d0%2Fcult%2Flink%2F&response_type=code&scope=identify'}>Connect</button>
      </div>
    </>
  );
}

export default App;
